import { CircularProgress, TextField } from "@mui/material";
import * as React from "react";
import { useCallback } from "react";

import { isValidKeysInput } from "../../utils/isElement";
import KeybindHint from "../KeybindHint";

import TableContent, { TableParams } from "./Table";
import { TableHeaderCellProps } from "./TableHeaderCell";

import TitledPage from "@/modules/common/components/layout/pages/TitledPage";
import debounce from "@/modules/common/utils/debounce";

export type TablePageProps<T> = {
  data: T[];
  isLoading: boolean;
  isFetching?: boolean;
  params: TableParams;
  pageTitle?: string;
  pageTitleIcon?: React.ElementType;
  onParamsChange: (params: Partial<TableParams>) => void;
  totalCount?: number;
  renderRow: (row: T) => React.ReactNode;
  headers: TableHeaderCellProps[];
  toolbarAction?: React.ReactNode;
  aditionalToolbar?: React.ReactNode;
  disablePadding?: boolean;
  rowsPerPageOptions?: number[];
  isFullscreen?: boolean;
  hasSearch?: boolean;
  filters?: React.ReactNode;
};

function TablePage<T>({
  data,
  isLoading,
  isFetching,
  onParamsChange,
  params,
  totalCount,
  pageTitle,
  pageTitleIcon,
  renderRow,
  headers,
  toolbarAction,
  aditionalToolbar,
  disablePadding,
  rowsPerPageOptions,
  isFullscreen,
  filters,
  hasSearch = true,
}: TablePageProps<T>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilterChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      onParamsChange({ search: e.target.value, page: 1 });
    }),
    [onParamsChange]
  );

  return (
    <TitledPage
      title={pageTitle}
      icon={pageTitleIcon}
      disablePadding={disablePadding}
      actions={
        <>
          {filters}
          {hasSearch && (
            <TableSearch
              defaultSearch={params.search}
              isFetching={isFetching}
              onFilterChange={onFilterChange}
            />
          )}
          {toolbarAction}
        </>
      }
    >
      {aditionalToolbar}

      <TableContent<T>
        data={data}
        isLoading={isLoading}
        onParamsChange={onParamsChange}
        page={params.page}
        perPage={params.perPage}
        sortBy={params.sortBy}
        sortOrder={params.sortOrder}
        headers={headers}
        renderRow={renderRow}
        rowsPerPageOptions={rowsPerPageOptions}
        totalItems={totalCount}
        isFullscreen={isFullscreen}
      />
    </TitledPage>
  );
}

const TableSearch = ({
  onFilterChange,
  defaultSearch,
  isFetching,
}: {
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultSearch?: string;
  isFetching?: boolean;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isValidKeysInput(e.target)) return;
      if (e.key === "/") {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputRef]);

  return (
    <TextField
      label="Search"
      variant="outlined"
      type="text"
      inputRef={inputRef}
      defaultValue={defaultSearch}
      onChange={onFilterChange}
      fullWidth
      sx={{ maxWidth: { sm: "28rem" } }}
      size="small"
      autoFocus
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      InputProps={{
        endAdornment: isFetching ? (
          <CircularProgress size={20} />
        ) : isFocused ? (
          <KeybindHint kbd="esc" bold={false} />
        ) : (
          <KeybindHint kbd="/" />
        ),
      }}
    />
  );
};

export default TablePage;
