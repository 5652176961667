import { Box, TableCell, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { SortOrder } from "@/modules/common/types/sharedTypes";

export type TableHeaderCellProps = {
  label: string | React.ReactNode;
  sortKey?: string;
  // column is sortable
  isSorting?: boolean;
  // column is being sorted
  sortOrder?: SortOrder;
  onSortChange?: (sortBy: string, sortOrder: SortOrder) => void;
  textAlign?: "left" | "center" | "right";
  noWrap?: boolean;
} & React.ComponentProps<typeof TableCell>;

export default function TableHeaderCell({
  label,
  sortKey,
  isSorting,
  sortOrder,
  textAlign = "left",
  onSortChange,
  noWrap,
  sx: sxProp,
  ...props
}: TableHeaderCellProps) {
  const isSortable = sortKey !== undefined;
  const sx = {
    whiteSpace: noWrap ? "nowrap" : "unset",
    ...(sxProp ?? {}),
  } as const;

  if (!isSortable) {
    return (
      <TableCell align={textAlign} sx={sx} {...props}>
        {label}
      </TableCell>
    );
  }

  return (
    <TableCell align={textAlign} sx={sx} {...props}>
      <TableSortLabel
        active={isSorting}
        direction={isSorting ? sortOrder : "asc"}
        onClick={() => {
          const isAsc = isSorting && sortOrder === "asc";
          onSortChange?.(sortKey, isAsc ? "desc" : "asc");
        }}
      >
        {label}
        {sortKey === label ? (
          <Box component="span" sx={visuallyHidden}>
            {sortOrder === "desc" ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
}
