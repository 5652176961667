// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function debounce<Fn extends (...args: any[]) => any>(
  func: Fn,
  wait = 500
) {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<Fn>): Promise<ReturnType<Fn>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        resolve(func(...args));
      }, wait);
    });
}
