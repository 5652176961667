function paramsToObject(
  entries: Iterable<[string, string]>
): Record<string, string> {
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export default paramsToObject;
