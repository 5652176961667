import { Divider, Skeleton, TableCell, TableContainer } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useCallback, useEffect, useRef, useState } from "react";

import TableHeaderCell, { TableHeaderCellProps } from "./TableHeaderCell";
import { defaultRowsPerPageOptions } from "./useTableDefaultRows";

import { SortOrder } from "@/modules/common/types/sharedTypes";
import range from "@/modules/common/utils/range";

export type TableParams = {
  search?: string;
  page: number;
  perPage: number;
  sortBy?: string;
  sortOrder?: SortOrder;
};

export type TableProps<T> = {
  data: T[];
  headers: TableHeaderCellProps[];
  renderRow: (row: T) => React.ReactNode;
  isLoading: boolean;
  onParamsChange: (params: Partial<TableParams>) => void;
  page: number;
  perPage: number;
  sortBy?: string;
  sortOrder?: SortOrder;
  totalItems?: number;
  rowsPerPageOptions?: number[];
  isFullscreen?: boolean;
};

export const TableLoader = ({
  perPage,
  colSpan,
  rowContentHeight = 4.5,
}: {
  perPage: number;
  colSpan: number;
  rowContentHeight?: number;
}) => (
  <>
    {range(1, perPage).map((_, index) => (
      <TableRow key={index}>
        <TableCell colSpan={colSpan}>
          <Skeleton height={rowContentHeight * 8 + 0.5}></Skeleton>
        </TableCell>
      </TableRow>
    ))}
  </>
);

function TableContent<T>({
  data,
  headers,
  renderRow,
  isLoading,
  onParamsChange,
  page,
  perPage,
  totalItems = 0,
  sortBy,
  sortOrder,
  rowsPerPageOptions,
  isFullscreen,
}: TableProps<T>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState(154);
  const handlePageChange = useCallback(
    (_: unknown, page: number) => {
      // mui pagination starts at 0, but we start at 1
      onParamsChange({ page: page + 1 });
    },
    [onParamsChange]
  );

  const handlePerPageChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        onParamsChange({ perPage: parseInt(e.target.value), page: 1 });
      },
      [onParamsChange]
    );

  const handleSortChange = useCallback(
    (sortBy: string, sortOrder: SortOrder) => {
      onParamsChange({ sortBy, sortOrder, page: 1 });
    },
    [onParamsChange]
  );

  useEffect(() => {
    if (!isFullscreen) {
      return;
    }
    const handleDistance = () => {
      if (tableContainerRef.current) {
        setDistance(tableContainerRef.current.getBoundingClientRect().top);
      }
    };
    handleDistance();
    window.addEventListener("resize", handleDistance);
    return () => {
      window.removeEventListener("resize", handleDistance);
    };
  }, [isFullscreen]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{
          // subtract distance from top, pagination, and app padding
          maxHeight: `calc(100vh - ${distance}px - 54px - 24px)`,
        }}
        ref={tableContainerRef}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              "& > tr > th": {
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[900]
                    : theme.palette.background.paper,
              },
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableHeaderCell
                  {...header}
                  key={index}
                  isSorting={sortBy === header.sortKey}
                  sortOrder={sortOrder}
                  onSortChange={handleSortChange}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader perPage={perPage} colSpan={headers.length} />
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={headers.length}>No data found.</TableCell>
              </TableRow>
            ) : (
              data.map((row) => renderRow(row))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <TablePagination
        showFirstButton
        showLastButton
        rowsPerPageOptions={rowsPerPageOptions ?? defaultRowsPerPageOptions}
        component="div"
        count={totalItems}
        rowsPerPage={perPage}
        // mui pagination starts at 0, but we start at 1
        page={totalItems <= 0 ? 0 : page - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePerPageChange}
      />
    </Paper>
  );
}

export default TableContent;
