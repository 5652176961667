import HttpClient from "../common/lib/httpClient";
import { ApiPagination, ApiQuery } from "../common/types/sharedTypes";

import type { BeaconDetails, BeaconsList } from "./Beacon.types";

const getOne = async (id: string): Promise<BeaconDetails> => {
  return HttpClient.get(`/beacons/${id}`);
};

const getList = async (
  params: ApiPagination &
    ApiQuery & { customer?: string; active: boolean | null }
): Promise<BeaconsList> => {
  return HttpClient.get(`/beacons`, params);
};

export default {
  getOne,
  getList,
};
