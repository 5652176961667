import { IMPLEMENTED_MODULES } from "./Auth";
import { useAuth } from "./AuthContext";

import { config } from "@/config";

export const useAdm4Rollback = (module: IMPLEMENTED_MODULES) => {
  const { user } = useAuth();

  if (
    config.enviroment !== "local" &&
    user?.rollback_modules?.includes(module)
  ) {
    location.reload();
  }
};
