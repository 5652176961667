import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { create } from "zustand";

import { FetcherError } from "../common/lib/httpClient/fetcher";

import Auth, {
  ALLOWED_ROLES,
  LoginParams,
  MeResponse,
  MfaParams,
} from "./Auth";

type AuthState =
  | "loading"
  | "unauthenticated"
  | "mfa-enroll"
  | "mfa-validate"
  | "authenticated";

type CheckAuthorization = <T extends ALLOWED_ROLES | ALLOWED_ROLES[]>(
  requiredRoles: T
) => T extends ALLOWED_ROLES[] ? boolean[] : boolean;

type AuthContextType = {
  authState: AuthState;
  user?: MeResponse;
  mfaEnrollSecret?: string;
  mfaEnrollQRcode?: string;
  login: (params: LoginParams) => Promise<void>;
  mfaValidate: (params: MfaParams) => Promise<void>;
  logout: () => Promise<void>;
  checkAuthorization: CheckAuthorization;
};

export const AuthContext = createContext<AuthContextType>({
  authState: "loading",
  login: () => Promise.resolve(),
  mfaValidate: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkAuthorization: () => false as any,
});

export const useAuthStore = create<{
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
}>((set) => ({
  authState: "loading",
  setAuthState: (authState) => set({ authState }),
}));

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { authState, setAuthState } = useAuthStore();
  const [token, setToken] = useState<string>();
  const { data: user, refetch } = useQuery({
    queryKey: ["me"],
    queryFn: () =>
      Auth.me()
        .then((resp) => {
          Sentry.setUser({
            username: resp.name,
            email: resp.email,
          });
          setAuthState("authenticated");
          return resp;
        })
        .catch((e) => {
          if (
            ["authenticated", "mfa-enroll", "mfa-validate"].includes(authState)
          ) {
            setToken(undefined);
            setAuthState("unauthenticated");
          }
          throw e;
        }),
  });

  const [mfaEnrollSecret, setMfaEnrollSecret] = useState<string>();
  const [mfaEnrollQRcode, setMfaEnrollQRcode] = useState<string>();

  const login = useCallback(async (params: LoginParams) => {
    const resp = await Auth.login(params);

    if (!resp.auth_token) {
      return;
    }

    setToken(`${resp.token_type} ${resp.auth_token}`);

    if (resp.mfa_enroll) {
      setAuthState("mfa-enroll");
      setMfaEnrollSecret(resp.mfa_secret);
      setMfaEnrollQRcode(resp.mfa_uri);
    } else {
      setAuthState("mfa-validate");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mfaValidate = useCallback(
    async ({ mfa }: MfaParams) => {
      try {
        await Auth.mfaValidate({ mfa }, token as string);
      } catch (e) {
        if ((e as FetcherError).message === "Unauthorized") {
          setAuthState("unauthenticated");
        }
        throw e;
      }
      refetch();
      setMfaEnrollSecret(undefined);
      setMfaEnrollQRcode(undefined);
      setToken(undefined);
    },
    [refetch, token, setAuthState]
  );

  const logout = useCallback(async () => {
    await Auth.logout();
    Sentry.setUser(null);
    setAuthState("unauthenticated");
    setToken(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authorizedRoles = useMemo(() => {
    if (!user) return undefined;
    return user.roles.reduce((acc, role) => {
      acc[role] = true;
      return acc;
    }, {} as Record<ALLOWED_ROLES, boolean>);
  }, [user]);

  const checkAuthorization = useCallback(
    (requiredRoles: ALLOWED_ROLES | ALLOWED_ROLES[]) => {
      if (typeof requiredRoles === "string") {
        return (authorizedRoles?.[requiredRoles] as boolean) ?? false;
      }
      return requiredRoles.map((role) => authorizedRoles?.[role] ?? false);
    },
    [authorizedRoles]
  );

  return (
    <AuthContext.Provider
      value={{
        authState,
        mfaEnrollSecret,
        mfaEnrollQRcode,
        login,
        mfaValidate,
        logout,
        user,
        checkAuthorization: checkAuthorization as CheckAuthorization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
