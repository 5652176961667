import HttpClient from "../common/lib/httpClient";
import {
  ApiPagination,
  ApiPaginationResponse,
  ApiQuery,
} from "../common/types/sharedTypes";

import type {
  BeaconTagsCreateParams,
  BeaconTagsEditParams,
  BeaconTagsItem,
  BeaconTagsList,
  CACFilter,
  CACFilterCreateParams,
  CACFilterEditParams,
  CACFiltersList,
  CACUser,
  CACUserCreateParams,
  CACUserEditParams,
  CustomerCreateParams,
  CustomerDetails,
  CustomerEditParams,
  CustomerPatchEditParams,
  CustomersItem,
  CustomersList,
  SponsorshipCreateParams,
  SponsorshipDetails,
  SponsorshipEditParams,
  SponsorshipItem,
  SponsorshipsList,
} from "./Customer.types";
import { SponsorshipCreateSchemaType } from "./schemas";

const getOne = async (id: string): Promise<CustomerDetails> => {
  return HttpClient.get(`/customers/${id}`);
};

const getList = async (
  params: ApiPagination & ApiQuery
): Promise<CustomersList> => {
  return HttpClient.get(`/customers`, params);
};

const create = async (
  customer: CustomerCreateParams
): Promise<CustomerDetails> => {
  return HttpClient.post(`/customers`, customer);
};

const edit = async (customer: CustomerEditParams): Promise<CustomerDetails> => {
  return HttpClient.put(`/customers/${customer.id}`, customer);
};

const editPatch = async (
  customer: CustomerPatchEditParams
): Promise<CustomerDetails> => {
  const { id, ...c } = customer;
  return HttpClient.patch(`/customers/${id}`, c);
};

const getCustomers = async (search: string): Promise<CustomersItem[]> => {
  return HttpClient.get(`/customers/filters-search`, {
    q: search,
  });
};

const listCACUsers = async (
  customerId: string,
  params: ApiPagination & ApiQuery & { active: boolean }
): Promise<ApiPaginationResponse<CACUser>> => {
  return HttpClient.get(`/customers/${customerId}/cacusers`, params);
};

const createCACUsers = async ({
  customerId,
  ...user
}: CACUserCreateParams): Promise<CACUser> => {
  if (user["timezone"] === null) {
    user["timezone"] = "";
  }
  return HttpClient.post(`/customers/${customerId}/cacusers`, user);
};

const editCACUsers = async ({
  id,
  customerId,
  password,
  confirm_password,
  ...user
}: CACUserEditParams): Promise<CACUser> => {
  if (user["timezone"] === null) {
    user["timezone"] = "";
  }
  return HttpClient.put(`/customers/${customerId}/cacusers/${id}`, {
    ...user,
    ...(password && { password, confirm_password }),
  });
};

const listCACFilters = async (
  id: string,
  params: ApiPagination & ApiQuery
): Promise<CACFiltersList> => {
  return HttpClient.get(`/customers/${id}/cacfilters`, params);
};

const createCACFilters = async ({
  customerId,
  ...filter
}: CACFilterCreateParams): Promise<CACFilter> => {
  const params = {
    ...filter,
    merged: filter["merged"]["id"],
  };
  return HttpClient.post(`/customers/${customerId}/cacfilters`, params);
};

const editCACFilters = async ({
  id,
  customerId,
  ...filter
}: CACFilterEditParams): Promise<CACFilter> => {
  return HttpClient.put(`/customers/${customerId}/cacfilters/${id}`, {
    ...filter,
  });
};

const deleteCACFilters = async ({
  customerId,
  filterId,
}: {
  customerId: string;
  filterId: string;
}): Promise<void> => {
  return HttpClient.delete(`/customers/${customerId}/cacfilters/${filterId}`);
};

const setCCPM = async ({
  customerId,
  ccpm,
}: {
  customerId: string;
  ccpm: boolean;
}): Promise<void> => {
  return HttpClient.put(`/customers/${customerId}/set_ccpm`, { ccpm });
};

const listBeaconTags = async (
  customerId: string,
  params: ApiPagination & ApiQuery
): Promise<BeaconTagsList> => {
  return HttpClient.get(`/customers/${customerId}/beacon_tags`, params);
};

const createBeaconTag = async ({
  customerId,
  ...tag
}: BeaconTagsCreateParams): Promise<BeaconTagsItem> => {
  return HttpClient.post(`/customers/${customerId}/beacon_tags`, tag);
};

const editBeaconTag = async ({
  customerId,
  id,
  ...tag
}: BeaconTagsEditParams): Promise<BeaconTagsItem> => {
  return HttpClient.patch(`/customers/${customerId}/beacon_tags/${id}`, tag);
};

const listSponsorships = async (
  customerId: string,
  params: ApiPagination & ApiQuery
): Promise<SponsorshipsList> => {
  return HttpClient.get(`/customers/${customerId}/sponsorships`, params);
};

const getSponsorship = async (
  customerId: string,
  sponsorshipsId: string
): Promise<SponsorshipItem> => {
  return HttpClient.get(
    `/customers/${customerId}/sponsorships/${sponsorshipsId}`
  );
};

const createSponsorships = async ({
  customerId,
  ...sponsorship
}: SponsorshipCreateParams & {
  customerId: string;
}): Promise<SponsorshipDetails> => {
  const formData = new FormData();
  Object.keys(sponsorship).forEach((key: string) => {
    formData.append(
      key as string,
      sponsorship[key as keyof SponsorshipCreateSchemaType]
    );
  });
  return HttpClient.post(`/customers/${customerId}/sponsorships`, formData);
};

const editSponsorships = async ({
  customerId,
  id,
  ...sponsorship
}: SponsorshipEditParams & {
  customerId: string;
}): Promise<SponsorshipDetails> => {
  const formData = new FormData();
  Object.keys(sponsorship).forEach((key: string) => {
    formData.append(
      key as string,
      (sponsorship as Record<string, string | Blob>)[key]
    );
  });
  return HttpClient.patch(
    `/customers/${customerId}/sponsorships/${id}`,
    formData
  );
};

export default {
  getOne,
  getList,
  create,
  edit,
  editPatch,
  getCustomers,
  listCACUsers,
  createCACUsers,
  editCACUsers,
  listCACFilters,
  createCACFilters,
  editCACFilters,
  deleteCACFilters,
  setCCPM,
  listBeaconTags,
  createBeaconTag,
  editBeaconTag,
  listSponsorships,
  getSponsorship,
  createSponsorships,
  editSponsorships,
};
