import { FormError, ResponseFormError } from "../../types/sharedTypes";

import { queryClient } from "@/appQueryClient";
import { config } from "@/config";
import { useAuthStore } from "@/modules/auth/AuthContext";

export const mountUrl = (path: string): string => {
  return `${config.api.baseUrl}${path}`;
};

export type FetcherError = {
  status: number;
  message: string;
  errors?: FormError[];
};

function addHeaders(options: RequestInit): RequestInit {
  const requestHeaders: HeadersInit = new Headers(options.headers);
  return {
    ...options,
    headers: requestHeaders,
    credentials: "include",
  };
}

const createConcurrentRefreshTokenHandler = () => {
  let refreshRequest: Promise<Response | void> | undefined = undefined;
  let isValidating = false;
  return (originalFetch: [string, RequestInit]) => {
    return new Promise((resolve, reject) => {
      const refreshToken = async () => {
        isValidating = true;
        refreshRequest = fetch(
          mountUrl("/auth/token"),
          addHeaders({
            method: "POST",
          })
        ).then((resp) => {
          if (resp.status === 200) {
            if (
              !originalFetch[0].includes("/me") &&
              !originalFetch[0].includes("/auth/logout")
            ) {
              queryClient?.refetchQueries({
                queryKey: ["me"],
              });
            }
            return resp;
          }

          useAuthStore.setState({ authState: "unauthenticated" });
          throw {
            status: resp.status,
            message: "Credentials expired",
          };
        });

        return refreshRequest;
      };

      if (!isValidating) {
        return refreshToken()
          .then(() => {
            isValidating = false;
            return resolve(fetcher(...originalFetch));
          })
          .catch((err) => {
            isValidating = false;
            return reject(err);
          });
      }

      return refreshRequest
        ?.then(() => resolve(fetcher(...originalFetch)))
        .catch(reject);
    });
  };
};

const handleConcurrentRefreshToken = createConcurrentRefreshTokenHandler();

export type FetcherDefaultError = {
  status: number;
  message: string;
};

export type FetcherValidationError = {
  status: number;
  message: string;
  errors: FormError[];
};

const handleResponse = async <T>(
  response: Response,
  originalFetch: [string, RequestInit]
) =>
  new Promise<T | null | FetcherError>((resolve, reject) => {
    if (response.ok) {
      return resolve(response.status === 204 ? null : response.json());
    }

    if (response.status === 422) {
      const rejectFormErrors = async (response: Response) => {
        const detail: ResponseFormError[] = (await response.json()).detail;
        const errors: FormError[] = detail.map(
          ({ loc: [, field, index], msg, type }) => ({
            index,
            field,
            message: msg,
            type,
          })
        );

        return reject({
          status: 422,
          message: "Validation failed",
          errors,
        } as FetcherValidationError);
      };

      return rejectFormErrors(response);
    }

    const getDefaultError = async (response: Response) => {
      const detail: string | unknown = (await response.json())?.detail;
      return reject({
        status: response.status,
        message: typeof detail === "string" ? detail : "Unknown error",
      } as FetcherDefaultError);
    };

    if (response.status === 401) {
      if (
        originalFetch[0].includes("/auth/login") ||
        originalFetch[0].includes("/auth/mfa")
      ) {
        return getDefaultError(response);
      }

      return handleConcurrentRefreshToken(originalFetch)
        .then((data) => resolve(data as T))
        .catch(reject);
    }

    return getDefaultError(response);
  });

async function fetcher<T>(url: string, options: RequestInit): Promise<T> {
  const optionsWithHeaders = addHeaders(options);

  return fetch(url, optionsWithHeaders)
    .catch(() =>
      Promise.reject({
        status: 500,
        message: "Internal server error",
      })
    )
    .then(async (data) =>
      handleResponse(data, [url, optionsWithHeaders])
    ) as Promise<T>;
}

export default fetcher;
