import { useMemo } from "react";

import Page403 from "../common/components/layout/pages/Page403";

import { config } from "@/config";
import { ALLOWED_ROLES, IMPLEMENTED_MODULES } from "@/modules/auth/Auth";
import { useAuth } from "@/modules/auth/AuthContext";

type Props =
  | {
      requiredRoles: ALLOWED_ROLES;
      mainRole?: never;
      element: JSX.Element;
    }
  | {
      requiredRoles: ALLOWED_ROLES[];
      mainRole: ALLOWED_ROLES | IMPLEMENTED_MODULES;
      element: JSX.Element;
    };

const AuthorizedElement = ({ element, requiredRoles, mainRole }: Props) => {
  const { checkAuthorization, user } = useAuth();

  const authorized = useMemo(() => {
    const authorization = checkAuthorization(requiredRoles);
    if (typeof authorization === "boolean") return authorization;
    return authorization.every((auth) => auth);
  }, [checkAuthorization, requiredRoles]);

  const shouldReload = useMemo(() => {
    if (!mainRole && Array.isArray(requiredRoles)) {
      throw new Error(
        "You must provide a mainRole if requiredRoles is an array"
      );
    }

    if (config.enviroment === "local") {
      return false;
    }

    return user?.rollback_modules?.includes(
      mainRole ?? (requiredRoles as ALLOWED_ROLES)
    );
  }, [mainRole, user, requiredRoles]);

  if (shouldReload) {
    location.reload();
    return null;
  }

  return authorized ? element : <Page403 />;
};

export default AuthorizedElement;
